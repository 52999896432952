import React from 'react';
import { ReactComponent as AppleIcon } from 'src/assets/apple.svg';
import { ReactComponent as PlayStoreIcon } from 'src/assets/play-store.svg';

const CTASection: React.FC = () => {
  return (
    <section className="mx-4 mt-[192px] max-w-6xl rounded-3xl bg-gradient-to-r from-purple-600 to-primary-500 sm:mx-8 lg:mx-auto">
      <div className="px-4 py-16 text-center sm:px-6 lg:px-8">
        <h2 className="mb-4 text-3xl font-bold text-white sm:text-4xl">
          Make the Switch to Workintercept
        </h2>
        <p className="mx-auto mb-8 max-w-2xl text-lg text-purple-100">
          Stop juggling tools and start simplifying your workflow. Try Workintercept now, for free.
        </p>

        <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
          <button className="inline-flex items-center rounded-lg bg-white px-6 py-3 text-gray-900 transition-colors hover:bg-gray-50">
            <AppleIcon />
            Apple Store
          </button>

          <button className="inline-flex items-center rounded-lg bg-white px-6 py-3 text-gray-900 transition-colors hover:bg-gray-50">
            <PlayStoreIcon />
            Play Store
          </button>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
