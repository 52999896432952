import { Clock } from 'lucide-react';
import { ReactComponent as PeopleIcon } from 'src/assets/people.svg';
import { Card } from '../ui/card';
const LeaveCards = () => {
  const cards = [
    {
      icon: (
        <div className="flex h-8 w-8 items-center justify-center rounded-md border-2 border-neutral-50">
          <PeopleIcon className="h-4 w-4 text-neutral-900" />
        </div>
      ),
      title: 'Total Leave request',
      value: '23',
      className: 'bg-[#8851c01f]',
    },
    {
      icon: <Clock className="h-6 w-6 text-neutral-900" />,
      title: 'Total Approved',
      value: '10',
      className: 'bg-gradient-to-br from-emerald-500/10 to-lime-500/10',
    },
    {
      icon: <Clock className="h-6 w-6 text-neutral-900" />,
      title: 'Total Rejected',
      value: '5',
      className: 'bg-red-100',
    },
    {
      icon: <Clock className="h-6 w-6 text-neutral-900" />,
      title: 'Total Pending',
      value: '15',
      className: 'bg-[#f9f3ae5c]',
    },
  ];
  return (
    <div className="mb-5 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {cards.map((card, index) => (
        <Card key={index} className={`flex flex-col border-none p-4 ${card.className}`}>
          <div className="flex items-center">
            <div className="mr-3">{card.icon}</div>
            <p className="text-sm font-medium text-black">{card.title}</p>
          </div>
          <h3 className="mt-6 text-2xl font-bold text-neutral-800">{card.value}</h3>
        </Card>
      ))}
    </div>
  );
};

export default LeaveCards;
