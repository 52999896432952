import { LucideIcon } from 'lucide-react';
import { ElementType } from 'react';

export interface Reason {
  icon: LucideIcon | ElementType;
  title: string;
  description: string;
}

interface ReasonCardProps extends Reason {
  key?: React.Key;
}

const ReasonCard: React.FC<ReasonCardProps> = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-4 text-center">
    <div className="mb-4">
      <Icon className="h-8 w-8 text-purple-600" />
    </div>
    <h3 className="mb-2 text-xl font-semibold text-gray-900">{title}</h3>
    <p className="leading-relaxed text-gray-600">{description}</p>
  </div>
);

export default ReasonCard;
