import FeatureCard from 'src/components/Home/FeatureCard';
import { ReactComponent as CalendarView } from 'src/assets/shift-calendar.svg';
import { ReactComponent as CheckInOutView } from 'src/assets/check-in-out.svg';
import { ReactComponent as InstantMessages } from 'src/assets/instant-msg.svg';
import { ReactComponent as SelfService } from 'src/assets/self-service.svg';

const FeaturesSection = () => {
  return (
    <section className="mt-20" id="features">
      <div className="text-center">
        <p className="mb-2 text-sm font-medium uppercase text-primary-500">Features</p>
        <h2 className="text-3xl font-semibold tracking-[-0.05em] text-gray-900 sm:text-[64px] sm:leading-[77.45px]">
          All-in-one solution <br /> for your team
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-6 px-8 pt-16 sm:grid-cols-2 sm:px-20">
        <FeatureCard
          title="Effortless Scheduling"
          description="Create and manage employee schedules quickly, with options to automate shifts and easily make changes when needed."
          caption={<CalendarView className="h-auto w-full" />}
        />
        <FeatureCard
          title="Simple Time Tracking"
          description="Let your team clock in and out with ease, and track hours accurately whether they’re in
            the office or working remotely."
          caption={<CheckInOutView className="h-auto w-full" />}
        />
        <FeatureCard
          title="Stay Connected with Built-In Communication"
          description="Instantly message, share updates, or collaborate on tasks with your team—all in one app."
          caption={<InstantMessages className="h-auto w-full" />}
        />
        <FeatureCard
          title="Employee Self-Service"
          description="Allow employees to manage their own schedules, request time off, and swap shifts without the back-and-forth."
          caption={<SelfService className="h-auto w-full" />}
        />
      </div>
    </section>
  );
};

export default FeaturesSection;
