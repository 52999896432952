import { LeaveReason } from 'src/types/leave';

export const status = [
  { value: 'ACTIVE', label: 'Active', id: '1' },
  { value: 'INACTIVE', label: 'Inactive', id: '2' },
];

export const accessLevel = [
  { value: 'Employee', label: 'Employee', id: 1 },
  { value: 'Manager', label: 'Manager', id: 2 },
  { value: 'Admin', label: 'Administration', id: 3 },
];

export const LeaveStatuses = [
  { value: 'Approved', label: 'Approved', id: '1' },
  { value: 'Rejected', label: 'Rejected', id: '2' },
  { value: 'Pending', label: 'Pending', id: '3' },
];

export const leaveReasons = Object.values(LeaveReason).map((value, i) => ({
  id: i + 1,
  value,
  label: value,
}));

export const currencyValues = [
  'USD',
  'EUR',
  'GBP',
  'NGN',
  'CAD',
  'AUD',
  'JPY',
  'CNY',
  'INR',
  'ZAR',
];

export const employmentType = ['Full-time', 'Part-time', 'Contractor'];
