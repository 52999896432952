import { UseFormRegister, FieldErrors, FieldValues } from 'react-hook-form';
import { Select, SelectTrigger, SelectContent, SelectItem } from 'src/components/ui/select';
import { Calendar } from 'src/components/ui/calendar';

import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import { ErrorMessage } from '@hookform/error-message';
import PasswordInput from 'src/components/onboarding/PasswordInput';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
export interface Option {
  value: string;
  label: string;
  id: number | string;
}

type FormGeneratorProps = {
  type?: 'text' | 'email' | 'password' | 'number';
  inputType: 'select' | 'input' | 'textarea' | 'date';
  options?: Option[];
  label?: string;
  placeholder: string;
  register: UseFormRegister<any>;
  name: string;
  errors?: FieldErrors<FieldValues>;
  setValue?: any;
  className?: string;
  watch?: any;
  disableDateRule?: (date: any) => boolean;
};

export const FormGenerator = ({
  inputType,
  options = [],
  label = '',
  placeholder,
  register,
  name,
  errors,
  type,
  className = '',
  setValue,
  watch,
  disableDateRule = () => false,
}: FormGeneratorProps) => {
  switch (inputType) {
    case 'textarea':
      return (
        <Label className={`flex flex-col gap-2 ${className}`} htmlFor={`input-${label}`}>
          {label && label}
          <textarea
            id={`input-${label}`}
            placeholder={placeholder}
            {...register(name)}
            className="border-input focus-visible:ring-ring flex min-h-[80px] w-full rounded-md border bg-background px-3 py-2 text-base ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
          />
        </Label>
      );
    case 'input':
      return (
        <>
          <Label className={`flex flex-col gap-2 ${className}`} htmlFor={`input-${label}`}>
            {label && label}
            {type === 'password' ? (
              <div>
                <PasswordInput
                  placeholder={placeholder}
                  register={register}
                  name={name}
                  label={label}
                />
                <p className="text-sm font-normal text-neutral-400">
                  Your password must contain 8 or more characters.
                </p>
              </div>
            ) : (
              <Input
                id={`input-${label}`}
                type={type}
                placeholder={placeholder}
                className="input-class"
                {...register(name)}
              />
            )}
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="mt-2 text-red-400">{message === 'Required' ? '' : message}</p>
              )}
            />
          </Label>
        </>
      );
    case 'select': {
      const selctedOption = options?.find((opt) => opt.value === watch(name)) || { label: '' };

      return (
        <Label htmlFor={`select-${label}`} className={`flex flex-col gap-2 ${className}`}>
          {label && label}
          <Select onValueChange={(value) => setValue(name, value)} value={watch(name)}>
            <SelectTrigger className="input-class w-full rounded-lg border-[1px] bg-transparent p-3 sm:p-4">
              <span> {selctedOption.label || placeholder} </span>
            </SelectTrigger>
            <SelectContent>
              {options?.length > 0 &&
                options.map((option) => (
                  <SelectItem value={option.value} key={option.id}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <p className="mt-2 text-red-400">
                {message === 'Required' ? `${label} is Required` : message}
              </p>
            )}
          />
        </Label>
      );
    }
    case 'date': {
      const selectedDate = watch(name) ? new Date(watch(name)) : null;

      return (
        <Label className={`flex flex-col gap-2 ${className}`}>
          {label && <span>{label}</span>}
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="input-class mt-1 py-[1.65rem]">
                {selectedDate ? format(selectedDate, 'PPP') : 'Pick a date'}
                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={watch(name)}
                onSelect={(date) => {
                  setValue(name, date);
                }}
                disabled={(date) => disableDateRule(date)}
                // disabled={(date) =>
                //   date > new Date() || date < new Date('1900-01-01')
                // }
              />
            </PopoverContent>
          </Popover>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <p className="mt-2 text-red-400">{message === 'Required' ? '' : message}</p>
            )}
          />
        </Label>
      );
    }

    default:
      return <></>;
  }
};
