import * as React from 'react';
import { LogOut, Settings } from 'lucide-react';
import { NavMain } from './navMain';
import { UserInfo } from '../shared/UserInfo';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenuButton,
} from 'src/components/ui/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard.svg';
import { ReactComponent as People } from 'src/assets/people.svg';
import { ReactComponent as Clock } from 'src/assets/clock.svg';
import { clearTokens } from 'src/lib/token';
import { RootState, useAppSelector } from 'src/redux/store';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const navigate = useNavigate();
  const { email, companyName } = useAppSelector((state: RootState) => state.user);

  const logout = () => {
    clearTokens();
    navigate('/login', { replace: true });
  };
  const data = {
    navMain: [
      {
        title: 'Dashboard',
        url: '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'Employees',
        url: '',
        icon: People,
        items: [
          {
            title: 'Employee Directory',
            url: '/dashboard/employees',
          },
          {
            title: 'Leave Requests',
            url: '/dashboard/leaves',
          },
        ],
      },
      {
        title: 'Time & Attendance',
        url: '/dashboard/time_attendance',
        icon: Clock,
      },
    ],
    userSetting: [
      {
        title: 'Setting',
        url: '#',
        icon: Settings,
      },
      {
        title: 'Log Out',
        url: '#',
        icon: LogOut,
        onClick: logout,
      },
    ],
  };

  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenuButton size="lg" asChild>
          <div className="flex items-center justify-between p-4">
            <Link to="/" className="flex cursor-pointer items-center gap-2 py-[9px]">
              <img src="/assets/logo.svg" className="h-[19.31px] w-[35.5px]" alt="logo" />
              <p className="text-lg font-semibold">WorkIntercept</p>
            </Link>
          </div>
        </SidebarMenuButton>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} className="mt-3" />
        <NavMain items={data.userSetting} className="mb-3 mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <UserInfo
          user={{
            identity: email,
            name: companyName,
            avatar: '',
          }}
        />
      </SidebarFooter>
    </Sidebar>
  );
}
