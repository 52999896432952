import React, { ReactNode } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getPaginationRowModel,
  Table,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';

import EmptyState from '../EmptyState';
import SkeletonTable from '../../Skeleton/TableSkeleton';
import Pagination from './pagination';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  toolbar?: (props: { table: Table<TData> }) => ReactNode;
  loading?: boolean;
}
const DataTable = <TData, TValue>({
  columns,
  data,
  toolbar,
  loading = false,
}: DataTableProps<TData, TValue>) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search] = React.useState('');

  const defaultData = React.useMemo(() => [], []);

  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  });

  const isEmpty = table.getRowModel().rows?.length === 0;
  return (
    <div className="border p-4">
      {toolbar ? toolbar({ table }) : null}
      {loading ? (
        <SkeletonTable rows={10} />
      ) : isEmpty ? (
        <EmptyState />
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full table-fixed border-collapse border-x border-t border-neutral-50">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className="border-b px-6 py-3 text-left text-sm font-medium leading-4 text-neutral-700"
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="whitespace-no-wrap border-b px-6 py-4 text-sm leading-5"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="h-2" />
          <Pagination table={table} />
        </>
      )}
    </div>
  );
};

export default DataTable;
