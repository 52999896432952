import { Button } from 'src/components/ui/button';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <section className="mt-20 px-4 text-center">
      <h2 className="text-4xl font-semibold tracking-[-0.05em] text-gray-900 sm:text-[64px] sm:leading-[77.45px]">
        Built for Teams That Get <br /> Things Done
      </h2>
      <p className="mt-4 pt-2 text-center text-base font-normal text-neutral-400">
        With our all-in-one platform, managing schedules, tracking time,
        <br className="hidden sm:block" /> and communicating has never been simpler.
      </p>
      <div className="mt-9 flex justify-center">
        <Button type="button" onClick={() => navigate('signup')} className="px-6 py-3">
          Get Started
        </Button>
      </div>
      <div className="mt-9 flex justify-center sm:px-20 md:px-36">
        <img src="/assets/image2.png" alt="dashboard sample" />
      </div>
    </section>
  );
};

export default HeroSection;
