import { LeaveStatus } from 'src/types/leave';
import { z } from 'zod';

export const CreateLeaveSchema = z.object({
  user: z.string().min(1, { message: 'Please select a employee' }),
  startDate: z.date({
    required_error: 'Start date is required.',
  }),
  endDate: z.date({
    required_error: 'end date is required.',
  }),
  reason: z.string().min(1, 'Reason is required'),
  status: z.nativeEnum(LeaveStatus).default(LeaveStatus.PENDING),
  comments: z.string().optional(),
});
