import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { Settings2 } from 'lucide-react';
import SelectField from 'src/components/shared/SelectField';
import { leaveReasons, LeaveStatuses } from 'src/constant/data';
import Search from '../shared/Search';
import { Table } from '@tanstack/react-table';

interface LeaveToolbarProps<TData> {
  table: Table<TData>;
}
const InitialState = {
  status: '',
  type: '',
};
const LeaveToolbar = <TData,>({ table }: LeaveToolbarProps<TData>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filters, setFilters] = useState(InitialState);

  const statusColumn = table.getColumn('status');
  const typeColumn = table.getColumn('reason');

  const applyFilters = () => {
    if (statusColumn && filters.status) {
      statusColumn?.setFilterValue(filters.status);
    } else {
      statusColumn?.setFilterValue(undefined);
    }

    if (typeColumn && filters.type) {
      typeColumn?.setFilterValue(filters.type);
    } else {
      typeColumn?.setFilterValue(undefined);
    }
    setIsPopoverOpen(false);
  };

  const resetFilters = () => {
    setFilters(InitialState);
    statusColumn?.setFilterValue(undefined);
    typeColumn?.setFilterValue(undefined);
    setIsPopoverOpen(false);
  };

  return (
    <div className="mb-4 flex justify-between">
      <Search />
      <div>
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="px-4 py-2">
              <span className="mr-2">
                <Settings2 size={20} />
              </span>
              Filter
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full px-3 py-4 sm:w-[212px]" align="end">
            <SelectField
              label="Status"
              placeholder="Select Status"
              options={LeaveStatuses}
              onChange={(value) => setFilters({ ...filters, status: value || '' })}
              value={filters.status}
            />
            <SelectField
              label="Leave Type"
              placeholder="Select Leave Type"
              options={leaveReasons}
              onChange={(value) => setFilters({ ...filters, type: value || '' })}
              value={filters.type}
            />

            <div className="mt-3 flex justify-between">
              <Button variant="outline" className="hover:bg-white" onClick={resetFilters}>
                Reset
              </Button>
              <Button
                onClick={applyFilters}
                className="bg-primary-500 px-5 py-2 text-white hover:bg-primary-500/90"
              >
                Apply
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default LeaveToolbar;
