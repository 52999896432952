import { Table } from '@tanstack/react-table';
import { Button } from 'src/components/ui/button';
import { getPaginationRange } from 'src/lib/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';

interface PaginationProps<TData> {
  table: Table<TData>;
}

const Pagination = <TData,>({ table }: PaginationProps<TData>) => {
  const totalPages = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex + 1; // 1-based index
  const paginationRange = getPaginationRange(currentPage, totalPages);

  return (
    <div className="mt-4 flex flex-col items-center justify-between sm:flex-row">
      <div className="flex items-baseline">
        <div className="mr-2 hidden sm:block">
          Page {currentPage} of {totalPages}
        </div>
        <Select
          value={String(table.getState().pagination.pageSize)}
          onValueChange={(value) => {
            table.setPageSize(Number(value));
          }}
        >
          <SelectTrigger className="w-[150px]">
            <SelectValue placeholder={`Show ${table.getState().pagination.pageSize}`} />
          </SelectTrigger>
          <SelectContent>
            {[3, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <SelectItem key={pageSize} value={String(pageSize)}>
                Show {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      {totalPages > 1 && (
        <div className="my-2 flex space-x-1 sm:my-0">
          {paginationRange?.map((page, index) => {
            if (page === '...') {
              return (
                <span key={index} className="px-3 py-1">
                  {page}
                </span>
              );
            }

            return (
              <button
                key={index}
                className={`px-3 py-1 ${
                  currentPage === page
                    ? 'rounded-md border border-primary-500 font-semibold text-primary-500'
                    : ''
                }`}
                onClick={() => table.setPageIndex(Number(page) - 1)}
              >
                {page}
              </button>
            );
          })}
        </div>
      )}
      <div>
        <Button
          className="rounded-md px-3 py-1 sm:mx-4"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          variant="outline"
        >
          Previous
        </Button>
        <Button
          className="rounded-md-black px-3 py-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          variant="outline"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
