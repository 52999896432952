import LeaveTable from 'src/components/leave/LeaveTable';
import { Plus } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import Header from 'src/components/shared/Header';
import LeaveCards from 'src/components/leave/LeaveCards';
import Drawer from 'src/components/shared/Drawer';
import { useState } from 'react';
import { FormGenerator } from 'src/components/shared/FormFieldGenerator';
import { useCreateLeave } from 'src/api/hooks/leave';
import { Employee } from 'src/types/employee';
import { Label } from 'src/components/ui/label';
import { LeaveReason } from 'src/types/leave';
import { useEmployees } from 'src/redux/selectors/employee';
import { Loader } from 'src/components/shared/Loader';

const Leaves = () => {
  const [openAddLeave, setOpenAddLeave] = useState(false);
  const { handleCreateLeave, register, watch, setValue, isPending, errors, reset } =
    useCreateLeave();

  const employees = useEmployees()?.map((employee: Employee) => ({
    id: employee._id,
    value: employee._id,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const handleCancel = () => {
    reset();
    setOpenAddLeave(false);
  };

  return (
    <div>
      <div className="mb-4 flex flex-row items-center justify-between">
        <Header title={'Leave Requests'} className="mb-0" />
        <div className="flex">
          <Button onClick={() => setOpenAddLeave(true)}>
            <Plus />
            <span className="ml-2">Add Leave</span>
          </Button>
        </div>
      </div>
      <LeaveCards />
      <LeaveTable />
      <Drawer
        open={openAddLeave}
        setOpen={setOpenAddLeave}
        className="gap-0"
        header={{
          title: 'Add Leave',
          description: 'Add leave for employee who request for it.',
        }}
      >
        <form onSubmit={handleCreateLeave} className="flex h-full flex-col pt-4">
          <div className="flex-1 space-y-5 px-6">
            <FormGenerator
              register={register}
              watch={watch}
              errors={errors}
              inputType="select"
              placeholder="Select Employee"
              setValue={setValue}
              label="Select Employee"
              name="user"
              options={employees || []}
              className=""
            />
            <Label className="flex flex-col gap-2" htmlFor="leaveType">
              Leave Type
              <ul className="flex flex-wrap gap-3">
                {Object.values(LeaveReason).map((reason) => (
                  <li
                    key={reason}
                    onClick={() => setValue('reason', reason)}
                    className={` ${reason === watch('reason') ? 'bg-primary-50 font-semibold' : ''} cursor-pointer rounded-md border border-neutral-200 px-4 py-[14px] text-sm font-medium text-neutral-300 hover:bg-neutral-50`}
                  >
                    {reason}
                  </li>
                ))}
              </ul>
            </Label>
            <div className="flex justify-between">
              <FormGenerator
                register={register}
                errors={errors}
                inputType="date"
                placeholder="Start Date"
                label="Start Date"
                name="startDate"
                className="w-[49%]"
                watch={watch}
                setValue={setValue}
                disableDateRule={(date) => date > watch('endDate')}
              />
              <FormGenerator
                register={register}
                errors={errors}
                inputType="date"
                placeholder="End Date"
                label="End Date"
                name="endDate"
                className="w-[49%]"
                watch={watch}
                setValue={setValue}
                disableDateRule={(date) => date < watch('startDate')}
              />
            </div>
            <FormGenerator
              register={register}
              watch={watch}
              errors={errors}
              inputType="textarea"
              placeholder="Reason for leave"
              setValue={setValue}
              label="Reason for leave"
              name="comments"
            />
          </div>
          <div className="mt-6 flex justify-end space-x-3 border-t p-6">
            <Button
              type="button"
              onClick={handleCancel}
              variant="outline"
              className="hover:bg-slate-50"
            >
              Cancel
            </Button>
            <Button type="submit" className="bg-primary-500 font-semibold text-white">
              <Loader loading={isPending}>Add Leave</Loader>
            </Button>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default Leaves;
