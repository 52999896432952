import { ReactNode } from 'react';

type FeatureCardProps = {
  title: string;
  description: string;
  caption?: ReactNode;
};

const FeatureCard = ({ title, description, caption }: FeatureCardProps) => {
  return (
    <div className="rounded-lg bg-[#F7F8FA] px-8 py-5">
      <h2 className="text-2xl font-semibold tracking-[-0.05em] text-gray-900 sm:leading-[48px] md:text-[32px]">
        {title}
      </h2>
      <p className="mb-2 text-sm font-medium text-neutral-500">{description}</p>
      {caption && <div className="mt-8">{caption}</div>}
    </div>
  );
};

export default FeatureCard;
