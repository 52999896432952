import { z } from 'zod';

export const AddEmplyoeeSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address'),
  employmentTitle: z.string().min(1, 'EmploymentTitle is required'),
  employmentType: z
    .enum(['Full-time', 'Part-time', 'Contractor'])
    .refine((val) => ['Full-time', 'Part-time', 'Contractor'].includes(val), {
      message: 'Access level must be either Full-time, Part-time or Contractor',
    }),
  startDate: z.date({
    required_error: 'Start date is required.',
  }),
  department: z.string().min(1, 'Department ID is required'),
  accessLevel: z
    .enum(['Manager', 'Admin', 'Employee'])
    .refine((val) => ['Manager', 'Admin', 'Employee'].includes(val), {
      message: 'Access level must be Manager, Admin, or Employee',
    }),
  payRate: z.enum(['Hourly', 'Annually']).refine((val) => ['Hourly', 'Annually'].includes(val), {
    message: 'Pay rate must be Hourly or Annually',
  }),
  currency: z.string().min(3, 'Currency is required'),
  wagePerHour: z
    .string()
    .transform((val) => Number(val))
    .refine((val) => !isNaN(val) && val > 0, {
      message: 'Wage per hour must be a positive number',
    }),
});

export const UpdateEmployeeSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().email().optional(),
  role: z.string().optional(),
  startDate: z.date().optional(),
  department: z.string().optional(),
  accessLevel: z.enum(['Manager', 'Admin', 'Employee']).optional(),
  payRate: z.enum(['Hourly', 'Annually']).optional(),
  wagePerHour: z.number().positive().optional(),
  imgUrl: z.string().nullable().optional(),
  address: z.string().optional(),
  phoneNumber: z.string().optional(),
  dob: z.date().optional(),
  _id: z.string().optional(),
});
