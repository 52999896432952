import React from 'react';
import Reasons from './Reasons';

const WhyWorkIntercept = () => {
  return (
    <div className="mt-20" id="reasons">
      <div className="text-center">
        <p className="mb-2 text-sm font-medium uppercase text-primary-500">Why Workintercept</p>
        <h2 className="text-3xl font-semibold tracking-[-0.05em] text-gray-900 sm:text-[64px] sm:leading-[77.45px]">
          Work Smarter with <br /> Work Smarter with Workintercept
        </h2>
      </div>
      <Reasons />
      {/* <div className="grid grid-cols-1 gap-6 px-8 pt-16 sm:grid-cols-4 sm:px-20 md:grid-cols-3">
      

      </div> */}
    </div>
  );
};

export default WhyWorkIntercept;
