import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="mt-32 px-8 pb-10 sm:px-20">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-start justify-between space-y-8 lg:flex-row lg:items-center lg:space-y-0">
          <div>
            <Link to="/" className="flex cursor-pointer items-center gap-3">
              <img src="./assets/logo.svg" alt="logo" />
              <p className="text-2xl font-semibold">WorkIntercept</p>
            </Link>
          </div>

          <div className="flex space-x-16">
            <div>
              <h4 className="text-lg font-medium text-gray-900">Quicklinks</h4>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#features" className="text-gray-600 hover:text-gray-900">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#reasons" className="text-gray-600 hover:text-gray-900">
                    Why WorkIntercept
                  </a>
                </li>
                <li>
                  <a href="#pricing" className="text-gray-600 hover:text-gray-900">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#faqs" className="text-gray-600 hover:text-gray-900">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-lg font-medium text-gray-900">Socials</h4>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    X (formerly Twitter)
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-medium text-gray-900">Legals</h4>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-600 hover:text-gray-900">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-200 pt-6 text-center">
          <p className="text-sm text-gray-500">
            &copy; {new Date().getFullYear()} WorkIntercept. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
