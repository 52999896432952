import React from 'react';
import { Clock, LineChart } from 'lucide-react';
import { ReactComponent as SecurityLock } from 'src/assets/secuity-lock.svg';
import { ReactComponent as Smartphone } from 'src/assets/smart-phone.svg';

import ReasonCard, { Reason } from './ReasonCard';

const Reasons: React.FC = () => {
  const reasons: Reason[] = [
    {
      icon: Clock,
      title: 'Save Time, Reduce Stress',
      description:
        'Spend less time on admin tasks and more time focusing on what really matters—your team and your goals.',
    },
    {
      icon: SecurityLock,
      title: 'Secure and Reliable',
      description:
        'Your data is protected with top-notch security, so you can focus on work without worrying about privacy.',
    },
    {
      icon: Smartphone,
      title: 'Easy to Use, Anywhere',
      description:
        'With a clean design and mobile-friendly features, Workintercept keeps your team connected and productive, no matter where they are.',
    },
    {
      icon: LineChart,
      title: 'Data-Driven Insights',
      description:
        'Make smarter decisions with real-time reports and analytics that help you optimise your workforce management.',
    },
  ];

  return (
    <div className="mx-auto px-8 pt-16 sm:px-20">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
        {reasons.map((reason, index) => (
          <ReasonCard
            key={index}
            icon={reason.icon}
            title={reason.title}
            description={reason.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Reasons;
