import { BaseEntity } from './common';
import { Employee } from './employee';

export enum LeaveStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum LeaveReason {
  ANNUAL_LEAVE = 'Annual Leave',
  UNPAID_LEAVE = 'Unpaid Leave',
  SICK_LEAVE = 'Sick Leave',
  MATERNITY_LEAVE = 'Maternity Leave',
  PATERNITY_LEAVE = 'Paternity Leave',
  COMPASSIONATE_LEAVE = 'Compassionate Leave',
  OUT_OF_OFFICE = 'Out of Office/Business Trip',
  OUT_FOR_LUNCH = 'Out for Lunch',
  WORK_FROM_HOME = 'Work from Home',
  IN_LIEU = 'In Lieu',
}

export type PartialEmployee = Pick<Employee, '_id' | 'firstName' | 'lastName' | 'imgUrl'>;

export interface Leave extends BaseEntity {
  user: PartialEmployee;
  startDate: string;
  endDate: string;
  status: LeaveStatus;
  reason: string;
  company: string;
}

export interface UpdateLeaveStatus {
  id: string;
  status: LeaveStatus;
}

export interface LeaveRequest {
  user: string;
  startDate: Date;
  endDate: Date;
  reason: LeaveReason;
  status: LeaveStatus;
  department?: string;
}
