import { FC, useRef } from 'react';
import { Minus, Plus } from 'lucide-react';

export interface FAQItem {
  question: string;
  answer: string;
}

interface FAQItemProps extends FAQItem {
  isOpen: boolean;
  onClick: () => void;
}

const FAQ: FC<FAQItemProps> = ({ question, answer, isOpen, onClick }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="border-b border-neutral-50 shadow-none">
      <button
        className="flex w-full items-center justify-between border-none px-6 py-4 text-left hover:bg-gray-50 focus:outline-none"
        onClick={onClick}
        type="button"
        aria-expanded={isOpen}
      >
        <span className="text-lg text-neutral-900">{question}</span>
        {isOpen ? (
          <Minus className="h-5 w-5 text-neutral-900" />
        ) : (
          <Plus className="h-5 w-5 text-neutral-900" />
        )}
      </button>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-200 ease-in-out"
        style={{
          maxHeight: isOpen ? contentRef.current?.scrollHeight : 0,
        }}
      >
        <div className="bg-white px-6 py-4">
          <p className="text-gray-600">{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
