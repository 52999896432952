import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { toast } from 'src/lib/toast';
import {
  createLeave,
  deleteLeave,
  getCompanyLeaves,
  updateLeaveStatus,
} from '../services/leaveServices';
import { LeaveRequest, UpdateLeaveStatus } from 'src/types/leave';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateLeaveSchema } from 'src/validations/leaveSchema';
import { APIError } from './authentication';

const LEAVES = 'leaves';

export const useGetCompanyLeaves = (companyId: string) => {
  return useQuery({
    queryKey: [LEAVES, companyId],
    queryFn: () => getCompanyLeaves(companyId, ''),
    enabled: Boolean(companyId),
  });
};

export const useDeleteLeave = () => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: deleteLeave,
    onSuccess: () => {
      toast({
        message: `Leave deleted successfully`,
        position: 'bottom-left',
        type: 'success',
      });
    },
  });
  const queryClient = useQueryClient();

  const handleDelete = (id: string) => {
    if (!id) {
      toast({
        message: 'Please select a leave requast to delete',
        position: 'top-center',
        type: 'error',
      });
      return;
    }
    mutate(id || '');
  };

  if (isSuccess) {
    queryClient.invalidateQueries({ queryKey: [LEAVES] });
  }
  return {
    handleDelete,
    isPending,
    isSuccess,
  };
};

export const useUpdateLeaveStatus = () => {
  const { mutate, isSuccess } = useMutation({
    mutationFn: updateLeaveStatus,
    onSuccess: () => {
      toast({
        message: `Leave status updated successfully`,
        position: 'bottom-left',
        type: 'success',
      });
    },
  });
  const queryClient = useQueryClient();

  const handleUpdateStatus = (leave: UpdateLeaveStatus) => {
    if (!leave.id) {
      toast({
        message: 'Please select a leave requast to delete',
        position: 'top-center',
        type: 'error',
      });
      return;
    }
    mutate(leave);
  };

  if (isSuccess) {
    queryClient.invalidateQueries({ queryKey: [LEAVES] });
  }
  return {
    handleUpdateStatus,
  };
};

export const useCreateLeave = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<LeaveRequest>({
    resolver: zodResolver(CreateLeaveSchema),
    mode: 'onSubmit',
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: createLeave,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LEAVES] });
      reset();
      toast({
        message: 'Leave created successfully',
        position: 'top-center',
        type: 'success',
      });
    },
    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        type: 'error',
      });
    },
  });

  const handleCreateLeave = handleSubmit(async (data: LeaveRequest) => {
    return mutate(data);
  });

  return {
    handleCreateLeave,
    isPending,
    isSuccess,
    register,
    errors,
    watch,
    setValue,
    reset,
  };
};
