import { useState } from 'react';
import FAQ, { FAQItem } from './FAQ';

const FAQs = ({ faqs }: { faqs: FAQItem[] }) => {
  const [openItems, setOpenItems] = useState<Set<number>>(new Set());

  const toggleItem = (index: number): void => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(index)) {
      newOpenItems.delete(index);
    } else {
      newOpenItems.add(index);
    }
    setOpenItems(newOpenItems);
  };

  return (
    <section className="mx-auto mt-20 max-w-4xl px-4" id="faqs">
      <div className="text-center">
        <p className="mb-2 text-sm font-medium uppercase text-primary-500">FAQs</p>
        <h2 className="text-3xl font-semibold tracking-[-0.05em] text-gray-900 sm:text-[64px] sm:leading-[77.45px]">
          Frequently Asked Questions
        </h2>
      </div>

      <div className="mt-16 rounded-lg bg-white shadow-lg">
        {faqs.map((item, index) => (
          <FAQ
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openItems.has(index)}
            onClick={() => toggleItem(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default FAQs;
