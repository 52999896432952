import PricingCard from './PricingCard';

const Pricing = () => {
  return (
    <section className="mt-20" id="pricing">
      <div className="text-center">
        <p className="mb-2 text-sm font-medium uppercase text-primary-500">Pricing</p>
        <h2 className="text-3xl font-semibold tracking-[-0.05em] text-gray-900 sm:text-[64px] sm:leading-[77.45px]">
          Choose a plan that <br /> fits your team’s needs
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-6 px-8 pt-16 sm:grid-cols-2 sm:px-20 md:grid-cols-3">
        <PricingCard
          title="Basic Plan"
          description="Simplify shift management by bringing your team online"
          price="4"
          features={[
            'Access employee availability & time-off',
            'Automated shift scheduling',
            'Real-time notifications',
            'Unlimited users',
          ]}
        />
        <PricingCard
          title="Pro Plan"
          description="Simplify shift management by bringing your team online"
          price="6"
          features={[
            'Access employee availability & time-off',
            'Automated shift scheduling',
            'Real-time notifications',
            'Unlimited users',
            'Monitor attendance and overtime',
          ]}
        />
        <PricingCard
          title="Enterprise"
          description="Simplify shift management by bringing your team online"
          price="10"
          features={[
            'Access employee availability & time-off',
            'Automated shift scheduling',
            'Real-time notifications',
            'Unlimited users',
          ]}
        />
      </div>
    </section>
  );
};

export default Pricing;
