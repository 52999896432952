import React from 'react';
import { Button } from '../ui/button';
import { Check } from 'lucide-react';

type PricingCardProps = {
  title: string;
  description: string;
  price: string;
  features: string[];
};

const PricingCard: React.FC<PricingCardProps> = ({ title, description, price, features }) => {
  return (
    <div className="rounded-lg border border-neutral-100 bg-white p-6 hover:border-primary-500">
      <h3 className="text-xl font-semibold text-neutral-900">{title}</h3>
      <p className="mt-2 text-sm text-neutral-400">{description}</p>
      <div className="mt-4 text-4xl font-bold text-neutral-900">
        ${price} <span className="text-sm text-neutral-400">Per Employee/Month</span>
      </div>

      <Button className="mt-6 w-full rounded-md py-3 font-semibold">Get Started</Button>
      <div className="mt-6">
        <h4 className="text-sm font-semibold text-neutral-900">Features</h4>
        <ul className="mt-2 space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-sm text-neutral-300">
              <Check className="mr-2 text-primary-500" size={18} />
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingCard;
