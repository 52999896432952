export const faqData = [
  {
    question: 'What is Workintercept?',
    answer:
      'Workintercept is a comprehensive workplace monitoring and productivity analysis tool designed to help businesses track and optimize their workflow processes.',
  },
  {
    question: 'Who is Workintercept for?',
    answer:
      'Workintercept is designed for businesses of all sizes, from small teams to large enterprises, who want to improve productivity and gain insights into their workflow patterns.',
  },
  {
    question: 'Is Workintercept easy to use?',
    answer:
      'Yes, Workintercept features an intuitive interface and simple setup process. Most users can get started within minutes, with additional training resources available if needed.',
  },
  {
    question: 'Can I access Workintercept on my phone?',
    answer:
      'Yes, Workintercept is available on both iOS and Android devices, allowing you to monitor your workflow and receive updates on the go.',
  },
  {
    question: 'How much does Workintercept cost?',
    answer:
      'Workintercept offers flexible pricing plans starting from basic monitoring to enterprise-level solutions. Contact our sales team for detailed pricing information.',
  },
  {
    question: 'How do I get started with Workintercept?',
    answer:
      'Getting started is simple: sign up for an account on our website, choose your plan, and follow our quick setup guide. Our support team is available to help with the onboarding process.',
  },
  {
    question: 'Is my data secure with Workintercept?',
    answer:
      'Yes, we take data security seriously. Workintercept employs industry-standard encryption, regular security audits, and strict data protection protocols to ensure your information remains secure.',
  },
];
