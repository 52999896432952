import { Leave, LeaveRequest, UpdateLeaveStatus } from 'src/types/leave';
import { protectedApiClient } from '../clients/apiClient';

export const getCompanyLeaves = async (companyId: string, params: any): Promise<Leave[]> => {
  const { data } = await protectedApiClient.get(`/leaves/company/${companyId}`, {
    params,
  });
  return data;
};

export const deleteLeave = async (leaveId: string): Promise<any> => {
  const { data } = await protectedApiClient.delete(`/leaves/${leaveId}`);
  return data;
};

export const updateLeaveStatus = async (leave: UpdateLeaveStatus): Promise<any> => {
  const { data } = await protectedApiClient.put(`/leaves/${leave.id}`, {
    status: leave.status,
  });
  return data;
};

export const createLeave = async (leave: LeaveRequest): Promise<any> => {
  const { data } = await protectedApiClient.post('/leaves', leave);
  return data;
};
