import { type ClassValue, clsx } from 'clsx';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getPaginationRange = (currentPage: number, totalPages: number, siblingCount = 1) => {
  const totalPageNumbers = siblingCount + 5; // Show siblings + first, last, current, and ellipses

  if (totalPages <= totalPageNumbers) {
    return [...Array(totalPages).keys()].map((x) => x + 1); // Show all pages if within limit
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

  const shouldShowLeftEllipsis = leftSiblingIndex > 2;
  const shouldShowRightEllipsis = rightSiblingIndex < totalPages - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
    const leftRange = [...Array(3 + 2 * siblingCount).keys()].map((x) => x + 1);
    return [...leftRange, '...', totalPages];
  }

  if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
    const rightRange = [...Array(3 + 2 * siblingCount).keys()].map(
      (x) => totalPages - (3 + 2 * siblingCount) + x + 1,
    );
    return [firstPageIndex, '...', ...rightRange];
  }

  if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
    const middleRange = [...Array(rightSiblingIndex - leftSiblingIndex + 1).keys()].map(
      (x) => leftSiblingIndex + x,
    );
    return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
  }
};

export const getFirstLetters = (str: string) => {
  return str
    .split(' ')
    .map((word) => word[0])
    .join('');
};

export const getDaysDifference = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

export const formatDateRange = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const sameYear = start.isSame(end, 'year');
  const sameMonth = start.isSame(end, 'month');

  const startMonth = start.format('MMM Do');
  const endMonth = end.format('MMM Do');

  const dateRange = sameYear
    ? `${startMonth} - ${!sameMonth ? endMonth : end.format('Do')} ${start.format('YYYY')}`
    : `${startMonth}  ${end.format('YYYY')} - ${endMonth} ${start.format('YYYY')}`;
  return dateRange;
};
