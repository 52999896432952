import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import HeroSection from '../../components/Home/HeroSection';
import FeaturesSection from '../../components/Home/FeatureSection';
import Pricing from '../../components/Home/Pricing';
import FAQs from 'src/components/Home/FAQs';
import { faqData } from 'src/lib/dummyData/faqs';
import WhyWorkIntercept from 'src/components/Home/WhyWorkIntercept';
import CTASection from 'src/components/Home/CTASection';
import Footer from 'src/components/Home/Footer';
import { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Home = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <main className="h-screen w-full">
      <nav className="flex items-center justify-between bg-white px-6 py-4 shadow-md md:px-20 md:py-7">
        <div>
          <Link to="/" className="flex cursor-pointer items-center gap-3">
            <img src="./assets/logo.svg" alt="logo" className="h-8 w-8" />
            <p className="text-xl font-semibold md:text-2xl">WorkIntercept</p>
          </Link>
        </div>

        <div className="lg:hidden">
          {menuOpen ? (
            <X className="h-6 w-6 cursor-pointer" onClick={() => setMenuOpen(false)} />
          ) : (
            <Menu className="h-6 w-6 cursor-pointer" onClick={() => setMenuOpen(true)} />
          )}
        </div>

        <div
          className={`absolute left-0 top-[70px] w-full h-full bg-white shadow-md lg:static lg:flex lg:w-auto lg:bg-transparent lg:shadow-none ${
            menuOpen ? 'block' : 'hidden'
          }`}
        >
          <ul className="flex flex-col items-center space-y-4 px-6 pt-6 lg:flex-row lg:space-x-8 lg:space-y-0 lg:pt-0">
            <li>
              <a href="#" onClick={() => setMenuOpen(false)}>
                Home
              </a>
            </li>
            <li>
              <a href="#features" onClick={() => setMenuOpen(false)}>
                Features
              </a>
            </li>
            <li>
              <a href="#reasons" onClick={() => setMenuOpen(false)}>
                Why WorkIntercept
              </a>
            </li>
            <li>
              <a href="#pricing" onClick={() => setMenuOpen(false)}>
                Pricing
              </a>
            </li>
            <li>
              <a href="#faqs" onClick={() => setMenuOpen(false)}>
                FAQs
              </a>
            </li>
          </ul>

          <div className="mt-4 flex flex-col items-center space-y-4 px-6 pb-6 lg:mt-0 lg:flex-row lg:space-x-3 lg:space-y-0 lg:pb-0">
            <Button
              type="button"
              onClick={() => {
                navigate('login');
                setMenuOpen(false);
              }}
              variant="outline"
              className="hover:bg-slate-50"
            >
              Login
            </Button>
            <Button
              type="button"
              onClick={() => {
                navigate('signup');
                setMenuOpen(false);
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </nav>

      <HeroSection />
      <FeaturesSection />
      <WhyWorkIntercept />
      <Pricing />
      <FAQs faqs={faqData} />
      <CTASection />
      <Footer />
    </main>
  );
};

export default Home;
